<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="campaign"
      :search="search">
      <template #top>
        <v-toolbar
          flat>
          <v-toolbar-title>Campanhas</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true">
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #[`item.active`]="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          value
          disabled />
      </template>
      <template #[`item.initialDate`]="{ item }">
        <p>{{ item.initialDate | parseOnlyDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen>
      <form-campaign
        v-if="dialog"
        :populate-with="campaignToEdit"
        @update-list="loadCampaignList"
        @close-dialog="closeDialog" />
    </v-dialog>
  </div>
</template>

<script>
import CampaignService from '../../../services/campaign.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'Campaign',
  components: {
    'form-campaign': () => import('./Form'),
    // DeleteConfirmation,
  },

  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Título',
        sortable: false,
        value: 'title',
      },
      { text: 'Data de Inicio', value: 'initialDate', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],

    campaign: [],
    search: '',
    dialog: false,
    campaignToEdit: {},
    dialogDeleteConfirmation: false,
    dialogDelete: true,
    deleteItemID: null,
  }),

  async created() {
    await this.loadCampaignList();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.campaignToEdit = {};
    },

    editItem(item) {
      this.campaignToEdit = item;
      this.dialog = true;
    },

    async loadCampaignList() {
      const response = await CampaignService.getCampaign();
      this.campaign = response;
    },

    addCampaign(campaign) {
      this.campaign.push(campaign);
    },

    editCampaign(campaign) {
      const index = this.campaign.findIndex((listCampaign) => listCampaign.id === campaign.id);
      this.campaign[index] = campaign;
    },
  },
};
</script>
