import api from '@/services/api';

const url = 'campaign';

const getCampaign = () => api.get(`${url}/read-campaign`);

const createCampaign = (campaign) => api.post(`${url}/create-campaign`, campaign);

const updateCampaign = (campaign) => api.put(`${url}/update-campaign`, campaign);

const deleteCampaign = (id) => api.delete(`${url}/delete-campaign/${id}`);

export default {
  getCampaign, createCampaign, updateCampaign, deleteCampaign,
};
